<template>
  <div class="modal modal-fx-fadeInScale modal-pos-bottom">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{value}}</p>
        <button class="delete" aria-label="close"></button>
      </header>
      <section>
          <input class="input is-rounded" type="text" placeholder="Rounded input" v-if="(value == 'Create New Project')? true : false ">
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary is-light is-danger is-outlined is-rounded">NO</button>
        <div class="divider"/>
        <button class="button is-primary is-light is-normal is-outlined is-rounded" @click="(value == 'Create New Project')? createProject() : deleteProject()">YES</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
        }
    },
    props: {
        value: String
    },
    mounted(){
    },
    methods: {
        createProject(){
            console.log(this.value)
        },
        deleteProject(){
            console.log(this.value)
        }
    }
};
</script>

<style lang = 'scss' scoped>
.modal-card-foot{
    .divider{
        width:80%;
        height:auto;
        display:inline-block;
    }
}
</style>
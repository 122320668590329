import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import store from './store'
import router from './router'
import ProgressBar from 'primevue/progressbar';
import BlockUI from 'primevue/blockui';
import 'vuetify/dist/vuetify.min.css'
import 'primevue/resources/themes/mdc-light-indigo/theme.css'      //theme
import 'primevue/resources/primevue.min.css'              //core css
import 'primeicons/primeicons.css'                         //icons
import Dialog from 'primevue/dialog/sfc';
import Button from 'primevue/button';
const app = createApp(App)
app.use(PrimeVue);

app.use(router)
app.use(store)
app.mount('#app')
app.component("ProgressBar", ProgressBar)
app.component("BlockUI", BlockUI)
app.component("Dialog", Dialog)
app.component("Button", Button)
<template>
  <div class="home">
    <Navbar/>
    <img alt="Vue logo" src="../assets/logo.png">
    <Modal id="modal-id" :value='text'/>
    <div class="card">
  <footer class="card-footer">
    <a href="#" class="card-footer-item">Save</a>
    <a href="#" class="card-footer-item">Edit</a>
    <a href="#" class="card-footer-item">Delete</a>
  </footer>
</div>
<span class="button modal-button" data-target="modal-id" @click="text = 'Create New Project'" >Create New Project</span>
<span class="button modal-button" data-target="modal-id" @click="text = 'Delete Project'" >Delte Project</span>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Modal from '@/components/Modal.vue'

export default {
     data(){
        return{
            text : '',
        }   
    },
  name: 'Home',
  components: {
    Navbar,
    Modal
  }
}
</script>

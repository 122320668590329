<template>
    <div>
        <button @click="home = !home">Home</button>
        <button @click="about = !about">About</button>

        <Home  v-if="home"/>
        <About v-if="about"/>
    </div>
</template>
<script>
import Home from "./Home.vue";
import About from "./About.vue";
    export default {
        components: {
            Home,
            About
        },
        data(){
            return{
                home : false,
                about : false
            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>